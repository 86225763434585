import styled from 'styled-components';

export const Container = styled.div`
  max-width: 15rem;

  p {
    margin: 1rem 0;
    font-size: 1.2rem;
  }
`;
